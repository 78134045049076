$theme: 'refititYellow';
// Inspired by .is-gapless from bulma
.columns.is-gap-medium {
  margin-inline-start: 0;
  margin-inline-end: 0;

  margin-top: 0;

  & > .column {
    margin: 0rem;
    padding: 0.25rem !important;
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
