$theme: 'refititYellow';
@import '~bulma';

.quality-control {
  margin: auto;
  max-width: 600px;
  .card {
    .buttons.has-addons {
      .button {
        width: calc(100% / 3);
      }
    }
  }
  .package-deal-card {
    .package-deal-attachments-container {
      .card {
        height: 64px;
        @extend .mb-0;
        @extend .mr-2;
      }
    }
  }
}
