$theme: 'refititYellow';
.pageloader {
  text-align: center;
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

// Generated with https://loading.io/
@keyframes ldio-ge5i8uze656 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-ge5i8uze656 div {
  left: 44.5px;
  top: 5.5px;
  position: absolute;
  animation: ldio-ge5i8uze656 linear 1.4925373134328357s infinite;
  background: #6c6c6c;
  width: 11px;
  height: 15px;
  border-radius: 5.5px / 7.5px;
  transform-origin: 5.5px 44.5px;
}
.ldio-ge5i8uze656 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.3681592039800994s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.243781094527363s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -1.1194029850746268s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.9950248756218905s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.8706467661691542s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.7462686567164178s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.6218905472636815s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.49751243781094523s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3731343283582089s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.24875621890547261s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.12437810945273631s;
  background: #6c6c6c;
}
.ldio-ge5i8uze656 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #6c6c6c;
}
.loadingio-spinner-spinner-drn3j8p43kb {
  width: 41px;
  height: 41px;
  display: inline-block;
  overflow: hidden;
  // background: #ffffff;
}
.ldio-ge5i8uze656 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.41);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ge5i8uze656 div {
  box-sizing: content-box;
}
