$theme: 'refititYellow';
.add-kanban-message-container {
  border-radius: 8px;
  border: 2px solid lightgrey;
  .add-kanban-message-markdown-editor {
    .mdxeditor-toolbar {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .mdxeditor-diff-source-wrapper {
      padding: 12px;
    }
  }
}
